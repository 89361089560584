import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../components/floating-button/floating-button';
import Layout from '../../components/layout';
import React from 'react';

const BillonFinancialPage = (props) => {
    const components = props.data.cms.billonFinancialPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }
    return (
        <Layout
            location={props.location}
            locale={props.pageContext.locale}
            linksColor={`black`}
        >
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    )
}

export default BillonFinancialPage;

export const query = graphql`
query ($locale: String!){
  cms {
    billonFinancialPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsPdfDownload {
          __typename
          subtitle
          topPusherHeight
          topPusherHeightMobile
          pdf {
            title
            image {
              url
            }
            file {
              url
            }
          }
          sectionTitle {
            tailwindClasses
            description
            style
            title
            ytLink
          }
        }
        ... on STRAPI_ComponentComponentsServicesTable {
          __typename
          topPusherHeightMobile
          topPusherHeight
          service {
            tailwindClasses
            title
            servicesData
            bottomText
          }
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsFormWithSideText {
          __typename
          topPusherHeightMobile
          topPusherHeight
          text
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          buttonLabel
          formInput {
            inputType
            inputLabel
          }
        }
        ... on STRAPI_ComponentComponentsAboutFinancial {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            description
            style
            title
            ytLink
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;